import React, { Component } from 'react';
import phone from './phone.png';
import icon_mirro from './LogoClearBackground-small.png';
import icon_instagram from './instagram.svg';
import video_mirro from './video_mirro.mp4';
import './App.css';
import './AppMediaQueries.css';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isRetailers: false,
      showVideo: false
    };
  }

  render() {
    function changeAudience(audience, e) {
      if (audience === "retailers") {
        this.setState({ isRetailers: true }, () => {
          // console.log( this.state.isRetailers );
        }); 
      } else {
        this.setState({ isRetailers: false }, () => {
          // console.log( this.state.isRetailers );
        }); 
      }
    };

    function openVideo(e) {
      this.setState({ showVideo: !this.state.showVideo });
    };

    return (
      <div className="app">
        <div>
          <header className="header">
            <a href="https://mirroapp.com/" target="_self" className="header_left">
              <img src={icon_mirro} className="image_logo" alt="logo"/>
              Mirro
            </a>
           <div className="header_right">
            <a href="/#demo">{this.state.isRetailers ? "Request demo" : "Learn more"}</a>
            <a href="/#pricing">Pricing</a>
            {/*<a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className="link_button_small">Sign Up
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_dark">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </a>*/}
           </div>
          </header>
        </div>
        <section className="section1">
          <div className="section1_left">
            <div className="tag hide">#crowdsourcemystyle</div>
            <h1>Unlock Endless Outfit Inspiration!</h1>

            {this.state.showVideo ? <div class="modal_video">
              <div id="video">
                <center><video width="300" height="530" controls autoplay>
                  <source src={video_mirro} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video></center>
                <center><button onClick={openVideo.bind(this)} className="close_modal">Close Demo Video</button></center>
              </div>
            </div>: null}
            <div className="image_phone_small" onClick={openVideo.bind(this)}>
              <img src={phone} className="cursor_point image_phone_small" alt="phone"/>
            </div>
            <p className="width75">Mirro is the app that turns your closet into an endless source of inspiration. Let others work their magic with your clothes and redefine your fashion identity.</p>
            <a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className="link_button_large">
              {/*Waitlist for the iOS app*/}
              {/*Join the Beta*/}
              Download the app
              {/*Download Mirro and start creating outfits today!*/}
              <div className="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </div>
            </a>
            <p className="footnote width75">Download the app for iOS devices or access Mirro through your web browser on any other device! Android coming soon!</p>
          </div>
          <div className="section1_right" onClick={openVideo.bind(this)}>
            <img src={phone} className="cursor_point image_phone" alt="phone"/>
          </div>
        </section>
        <div id="demo" className="hide"><br/><br/><br/><br/></div>
        <section className="section2_outer hide">
          <h2>Community + AI, built for <button className="benefits-toggle_people" onClick={changeAudience.bind(this, "people")}>people</button> and <button className="benefits-toggle_retailers" onClick={changeAudience.bind(this, "retailers")}>retailers</button></h2>
          <div className={this.state.isRetailers ? `section2 ` : `toggle`}>
            <div className="card1 purple">
              <h3>Product Discovery</h3>
              <p>Mirro allows users to create outfits using real items from retailers. When users share these looks, it increases brand visibility and product awareness among a targeted audience.</p>
            </div>
            <div className="card1 purple">
              <h3>Interactive UGC</h3>
              <p>Retailers can collaborate with content creators on Mirro to create interactive challenges, encouraging user engagement with their products. </p>
            </div>
            <div className="card1 purple">
              <h3>Customer Insights</h3>
              <p>Mirro provides valuable data on user preferences, trending styles, and popular product combinations to inform marketing strategies, product development, and inventory management.</p>
            </div>
          </div>
          <div className={!this.state.isRetailers ? `section2` : `toggle`}>
            <div className="card1 yellow">
              <h3>Real Style by Real People</h3>
              <p>Get inspired by endless looks created by a community of fashion lovers, all using clothes you already own!</p>
            </div>
            <div className="card1 yellow">
              <h3>Dream it then Style it</h3>
              <p>Mirro uses generative AI to help you design looks & see them come to life before you even get dressed.</p>
            </div>
            <div className="card1 yellow">
              <h3>Dream Big but Shop Your Closet</h3>
              <p>Get inspired by designer looks & recreate them by combining your wardrobe staples with trending pieces.</p>
            </div>
          </div>
          <a href="https://forms.gle/bZArwxynV1JUewAh8" className={this.state.isRetailers ? `link_button_large dark` : `toggle`}>Request for a demo 
            <div className="arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </div>
          </a>
          <a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className={this.state.isRetailers ? `toggle` : `link_button_large yellow`}>
              {/*Waitlist for the iOS app*/}
              {/*Join the Beta*/}
              Download the app 
              {/*Download Mirro and start creating outfits today!*/}
              <div className="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </div>
            </a>
        </section>
        <div id="pricing" className="hide"><br/><br/><br/><br/></div>
        <section className="section2_outer">
          <h2>Pricing</h2>
          <div className="section2">
            <div className="card2">
              <div className="tag hide" style={{"opacity":0}}>Free</div>
              <h4>Free</h4>
              <div className="plan">FREE</div>
              <p>forever</p>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Explore Endless Outfit Inspiration</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Build Your Dream Digital Closet</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Design Unlimited Style Boards</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Unlimited Mirro Community Styling</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>5 AI Credits to Style Yourself or Others</div>
              <div className="faux_bullet" style={{"opacity":0}}></div>
              <a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className="link_button_large">Start for Free
                <div className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </div>
              </a>
            </div>
            <div className="card2">
              <div className="tag hide">#bestdeal</div>
              <h4>Standard</h4>
              <div className="plan">$12</div>
              <p>per month</p>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>All in Free +</div>
              {/*<div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Ad-Free</div>*/}
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>25 AI Credits to Style Yourself or Others</div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className="link_button_large">Get Standard
                <div className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </div>
              </a>
            </div>
            <div className="card2">
              <div className="tag hide" style={{"opacity":0}}>Pro</div>
              <h4>Pro</h4>
              <div className="plan">$45</div>
              <p>per month</p>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>All in Standard +</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Connect with Real Stylists Who Can Help You Elevate your Look</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>Get Personalized Attention to Catalog your Entire Wardrobe on Mirro</div>
              <div className="faux_bullet"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>50 AI Credits to Style Yourself or Others</div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <div className="faux_bullet hide" style={{"opacity":0}}></div>
              <a href="https://apps.apple.com/us/app/mirro-your-style-playground/id6474694135" className="link_button_large">Get Pro
                <div className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather-arrow-right_light">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </section>
        <footer className="footer">
          <center><a href="https://www.instagram.com/mirroapp/" target="_blank" rel="noopener noreferrer"><img src={icon_instagram} className="image_logo" alt="logo"/></a></center>
        </footer>
      </div>
    );
  }
}

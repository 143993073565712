import React, { Component } from 'react';

import mirro_icon from '../mirror-color-icon.svg';

import '../App.css';

export default class Faq extends Component {
  render() {
    return (
      <div>
        <div className="App">
          <header className="Header">
            <div className="Logo">
              <a href="/">
              	<img src={mirro_icon} className="Logo-icon" alt="Logo"/>
              	<span className="Logo-name">Mirro</span>
              </a>
            </div>
          </header>
          <section className="Section-1">
            <div className="Section-1-left">
              <div className="Section-1-left-content">
                <h1>FAQ</h1>
                <h3>What is Mirro?</h3>
                <p>Mirro is the app that turns your closet into an endless source of inspiration. Let others work their magic with your clothes and redefine your fashion identity.</p>
                <hr/>
                <h3>How do I sign up?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>How does the app work?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>What occasion am I dressing for? </h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>How do I add my style preferences?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>How do I add my body type and measurements?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>Can I try on outfits virtually?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>Can I get personalized styling advice?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>Where do I add my style preferences?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>What brands and retailers does the app work with?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>How much does the app cost?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
                <hr/>
                <h3>What are my feedback options? How can I contact customer support?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac orci phasellus egestas tellus rutrum. Lorem donec massa sapien faucibus et molestie ac feugiat. Interdum posuere lorem ipsum dolor sit. Sed turpis tincidunt id aliquet risus feugiat in.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
